<template>
  <div class="page-container">
    <div class="header-body">
      <el-card>
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="add-btn">
              <el-button
                type="primary"
                @click="addData.isOpen = !addData.isOpen"
                >添加</el-button
              >
            </div>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <div class="table-body">
      <el-card>
        <el-table :data="userList" style="width: 100%" border stripe>
          <el-table-column type="index" label="ID" width="50">
          </el-table-column>
          <el-table-column prop="username" label="账号"> </el-table-column>
          <el-table-column prop="roleNames" label="角色"> </el-table-column>
          <el-table-column prop="sex" label="性别">
            <template v-slot="scoped">
              <el-tag
                :type="Number(scoped.row.sex) === 1 ? 'warning' : 'danger'"
              >
                {{ Number(scoped.row.sex) == 1 ? "男" : "女" }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="sex" label="查看全部">
            <template v-slot="scoped">
              <el-switch
                v-model="scoped.row.isHighest"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="2"
                active-text="是"
                inactive-text="否"
                @change="isHighestUser(scoped.row.isHighest, scoped.row.id)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="phone" label="手机号码"> </el-table-column>
          <el-table-column prop="loginTime" label="登录时间"> </el-table-column>
          <el-table-column label="操作" width="400">
            <template v-slot="scoped">
              <el-button
                type="primary"
                size="small"
                @click="openSetRoleDialog(scoped.row.id)"
                >分配角色</el-button
              >
              <el-button
                type="success"
                size="small"
                @click="openBindPromoterDialog(scoped.row.id)"
                >绑定推广员</el-button
              >
              <el-button
                type="warning"
                size="small"
                @click="updateUserHandle(scoped.row.id)"
                >编辑</el-button
              >
              <el-button
                type="danger"
                size="small"
                @click="deleteUserHandle(scoped.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
    <div class="pagination-body">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.currentPage"
        :page-sizes="[1, 2, 5, 10]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <set-role-dialog :dialogData="dialogData" @closeDialog="closeDialog" />
    <add-user :addData="addData" @closeDialog="closeDialog" />
    <edit-user :editData="editData" @closeDialog="closeDialog" />
    <bind-promoter :promoterData="promoterData" @closeDialog="closeDialog" />
  </div>
</template>

<script>
import { getUser, deleteUser, updateHighest } from '@/api/login.js'
import setRoleDialog from './dialog/setRole.vue'
import addUser from './dialog/addUser.vue'
import editUser from './dialog/editUser.vue'
import bindPromoter from './dialog/bindPromoter.vue'

export default {
  components: {
    setRoleDialog,
    addUser,
    editUser,
    bindPromoter
  },
  data () {
    return {
      userList: [],
      queryInfo: {
        currentPage: 1,
        pageSize: 10
      },
      total: 0,
      isAddDialog: false,
      dialogData: {
        isOpen: false,
        id: ''
      },
      addData: {
        isOpen: false
      },
      editData: {
        isOpen: false,
        id: ''
      },
      promoterData: {
        isOpen: false,
        id: ''
      }
    }
  },
  created () {
    this.getUser()
  },
  methods: {
    /* 获取用户列表 */
    getUser () {
      getUser(this.queryInfo).then(res => {
        this.userList = res.data.list
        this.total = res.data.total
      })
    },
    // 更改用户是否可以查看全部学生
    isHighestUser (isHighest, id) {
      const data = {
        isHighest,
        id
      }
      updateHighest(data)
    },
    /* 分页选择 */
    handleSizeChange (size) {
      this.queryInfo.pageSize = size
      this.getUser()
    },
    handleCurrentChange (size) {
      this.queryInfo.currentPage = size
      this.getUser()
    },
    /* 打开更新窗口 */
    updateUserHandle (id) {
      this.editData = {
        isOpen: true,
        id: id
      }
    },
    /* 打开分配角色弹窗 */
    openSetRoleDialog (id) {
      this.dialogData = {
        isOpen: true,
        id: id
      }
    },
    /* 打开账号绑定推广员弹窗 */
    openBindPromoterDialog (id) {
      this.promoterData = {
        isOpen: true,
        id: id
      }
    },
    /* 关闭弹窗 */
    closeDialog (data) {
      this.dialogData.isOpen = data.isOpen
      this.addData.isOpen = data.isOpen
      this.editData.isOpen = data.isOpen
      this.promoterData.isOpen = data.isOpen
      if (data.type === 'success') {
        this.getUser()
      }
    },
    /* 删除用户 */
    deleteUserHandle (id) {
      this.$confirm('此操作将永久删除该专业, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteUser(id).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getUser()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  .header-body {
    .add-btn {
      text-align: right;
    }
  }
  .pagination-body {
    .el-pagination {
      text-align: center;
      padding: 20px 0;
    }
  }
}
</style>
